//
// Login 3
// Pages SASS files are compiled into separate css files
//


// Initialization of global variables, mixins and functions
@import "../../init";

.login.login-3 {
    // Form modes
    .login-signin,
    .login-signup,
    .login-forgot {
        display: none;
    }

    &.login-signin-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: block;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-signup-on {
        .login-signup {
            display: block;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-forgot-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: block;
        }
    }
}
.login-divider {
    margin: 1rem 0 2rem 0;

    &:not(:first-child):not(:last-child) {
        font-weight: 400;
        color: #b5b2c3;
        font-size: 1rem;
    }
    .divider {
        display: flex;
        justify-content: center;
        align-items: center;      
    
        > span {             
            &:first-child {
                width: 100%;
                height: 1px;                 
                flex: 1;
                background: #EBECF1;
                display: inline-block;
            }
    
            &:last-child {
                width: 100%;
                height: 1px;               
                flex: 1;
                background: #EBECF1;
                display: inline-block;
            }
    
            &:not(:first-child):not(:last-child) {
                padding: 0 2rem;                           
            }
        }
    }
    
}

// Options
.login-options {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    max-width: 100%;

    > a {
        text-decoration: none;
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;

        &:not(:last-child) {
            margin: 0 1.5rem 0 0;
        }
    }
}

// Desktop view
@include media-breakpoint-up(lg) {
    .login.login-3 {
        .login-aside {
            width: 100%;
            max-width: 600px;
            background-image: url('/media/bg/login_web.jpg');
            background-position: bottom;
        }
        .login-title {
            padding-bottom:35%;
        }
        .login-form {
            width: 100%;
            max-width: 450px;
        }
        .login-wechat {
            width:100%;
            height:100%;
            border:0;
            padding-top:20%;
        }
    }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
    .login.login-3 {
        .login-aside {
            width: 100%;
            max-width: 450px;
        }
    }
}

// Tablet mode
@include media-breakpoint-down(md) {
    .login.login-3 {
        .login-form {
            width: 100%;
            max-width: 400px;
        }
        .login-title {
            padding-top:5%;
            padding-bottom:20%;
        }
        .login-aside {
            background-image: url('/media/bg/login_mobile.jpg');
            background-position: bottom;
        }
        .login-desc {
            width:50%;
        }
        .login-wechat {
            width:100%;
            height:100%;
            border:0;
            padding-top:0;
        }
    }

}

// Mobile mode
@include media-breakpoint-down(xs) {
    .login.login-3 {

        .login-form {
            width: 100%;
            max-width: 100%;
        }
        .login-title {
            padding-top:5%;
            padding-bottom:20%;
        }
        .login-aside {
            background-image: url('/media/bg/login_mobile.jpg');
            background-position: bottom;
        }
        .login-desc {
            width:50%;
        }
        .login-wechat {
            width:100%;
            height:100%;
            border:0;
            padding-top:0;
        }
    }

}
