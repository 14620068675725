table.datagrid {
    width: 100%;
  }

.data-grid-container .data-grid .cell .value-viewer {
    display: block;
    box-sizing: content-box;
    width: inherit;
    word-break:break-all;
  }
 
  .data-grid-container .data-grid .cell .data-editor{
    display: block;
    box-sizing: content-box;
    height: inherit;
    word-break:break-all;
  }

  .data-grid-container .data-grid .cell.read-only .value-viewer{
    word-break:keep-all !important;
  }

  .data-grid-container .data-grid .cell .hint {
    position: relative;
    display: inline-block;
  }
  

  .data-grid-container .data-grid .cell[data-hint] {
    background-color: pink;
  }

/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-hint] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-hint]:before,
[data-hint]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-hint]:before {
  position: absolute;
  bottom: 130%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(29, 100%, 55%, 0.9);
  color: #fff;
  content: attr(data-hint);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-hint]:after {
  position: absolute;
  bottom: 130%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(29, 100%, 55%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-hint]:hover:before,
[data-hint]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}